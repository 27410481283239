<template>
    <div class="page">
        <to-history :prop-message="title"></to-history>
        <div class="search_wap">
            <div class="a-inline-block">
                <a-input-search v-model="tmjcpb_corp__name" class="sInput" placeholder="请输入关联客户" @search="onSearch" />
            </div>
        </div>
        <div class="lists" v-for="(item, index) in data" :key="index">
            <div class="list_row">
                    <div class="list_lable">关联客户：<span>{{ item.name }}</span></div>
                    <div class="list_lable">类型：<span>{{ item.type }}</span></div>
                    <div class="list_lable">联系人：<span>{{ item.zd1719102503 }}</span></div>
                    <div class="list_lable">联系电话：<span>{{ item.zd1719102509 }}</span></div>
                    <div class="list_lable">添加人：<span>{{ item.creater_uid }}</span></div>
                    <div class="list_lable">添加时间：<span>{{ item.created }}</span></div>
                <!-- <div class="flex">
                    <div><label for="">关联客户：</label>{{ item.name }}</div>
                    <div><label for="">类型：</label>{{ item.type }}</div>
                </div>
                <div class="flex">
                    <div><label for="">联系人：</label>{{ item.zd1719102503 }}</div>
                    <div><label for="">联系电话：</label>{{ item.zd1719102509 }}</div>
                </div>
                <div class="flex">
                    <div><label for="">添加人：</label>{{ item.creater_uid }}</div>
                    <div><label for="">添加时间：</label>{{ item.created }}</div>
                </div> -->
            </div>
            <div class="btn_con">
                <template>
                    <a-button style="background: #FF9100 !important;" ghost @click="showDetail(item.id)">
                        <img src="@/assets/imgs/wap/see.png" alt="">
                        查看</a-button>
                    <a-button style="background:  #436DBB !important;" ghost @click="editOrder(item.id)">
                        <img src="@/assets/imgs/wap/edit.png" alt="">编辑</a-button>
                    <a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(item.id)">
                        <a-button style="background: transparent !important; color: #436DBB;border:1px solid #436DBB;" ghost>
                        <img src="@/assets/imgs/wap/del.png" alt="">删除</a-button>
                    </a-popconfirm>
                </template>
            </div>
        </div>

    </div>
</template>
<script>
// import $ from "jquery";
import '../../../assets/css/wapList.css';
import { requestXml, getDateTime } from '../../../assets/js/request';
export default {
    data() {
        return {
            // 搜索的内容
            tmjcpb_corp__name: "",
            orderStatus: ["取消", "新建", "进行中", "已完成"],
            data: [],
            title:this.$route.query.title

        };
    },
    mounted: function () {
        this.getOrder();
    },
    methods: {
        // 获取订单列表
        getOrder() {
            requestXml("/scrm/corp/purchase", "POST", (res) => {
                for (let i = 0; i < res.list.length; i++) {
                    res.list[i].created = getDateTime(res.list[i].created);
                    res.list[i].type = res.list[i].type.toString().indexOf(1)>-1 &&  res.list[i].type.toString().indexOf(2)>-1? '团购,外卖' :res.list[i].type.toString().indexOf(1)>-1?'团购': res.list[i].type.toString().indexOf(2)>-1?'外卖':''
                }
                this.data = res.list;
            }, { "tmjcpb_corp__name": this.tmjcpb_corp__name })
        },
        onSearch() {
            this.getOrder();
        },
        // 查看
        showDetail(id) {
            this.$router.push({
                path: "/scrm_wap/client/purchaseDetail",
                query: {
                    id: id,
                }
            });
        },
        // 编辑
        editOrder(id) {
            this.$router.push({
                path: "/scrm_wap/client/addPurchase",
                query: {
                    id: id,
                }
            });
        },
        // 删除
        onDelete(id) {
            requestXml("/scrm//corp/delpurchase", "POST", (res) => {
                console.log(res)
                this.getOrder();
            }, { "id": id })
        }

    }
};
</script>
<style scoped>

.page {
    background-image: url('../../../assets/imgs/wap/bg.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% auto;
    padding-bottom: 50px;
}

/deep/.ant-input {
    background: #3056BF !important;
    color: #fff;
}

/deep/ .ant-input::placeholder,
/deep/ .ant-select::placeholder {
    color: #fff;
    font-size: 12px;
    /* 将占位符文本颜色设置为灰色 */
}

/deep/ .anticon svg,
/deep/ .ant-select svg {
    color: #fff;
}
.list_lable{
    color: #666666;
}
.list_lable span{
    color: #000000;
    float: right;
}
.btn_con{
    display: flex;
}
.btn_con button{
    width: 200px;
    height: 40px;
    line-height: 18px;
    color: #fff;
    border-radius: 30px;
    border: none;
    font-size: 18px;
}
.btn_con button img{
    height: 18px;
    vertical-align:middle;
    margin-right: 5px;
}
</style>
